import React, { Component } from 'react'
import SectionHeader from '../SectionHeader/SectionHeader'
import ReactGA from 'react-ga'
import './Menu.css'

class Menu extends Component{

  componentDidMount(){
    ReactGA.initialize(this.props.rest.ga_id)
  }

  componentDidUpdate(prevProps){
    if (this.props.isVisible && !prevProps.isVisible){
      this.props.setRoute('menu')
      ReactGA.pageview('Menu')
    }
  }

  renderMenuBody(){
    let categories = []
    let cat_arr = Object.keys(this.props.rest.html_menu).map((key)=> {
      return [key, this.props.rest.html_menu[key]];
    })
    cat_arr.sort((first, second)=> {return second[1].length - first[1].length;})
    let sorted_categories = {}
    for (var cat in cat_arr){
      sorted_categories[cat_arr[cat][0]] = cat_arr[cat][1]
    }

    for (var category in sorted_categories) {
      categories.push(
        <div key={categories.length} className='MenuCategoryContainer'>
          <h4 className='MenuCategory' style={{color:this.props.rest.hex_color_secondary}}>{category}</h4>
          {sorted_categories[category].map((item,i)=>{
            return(
              <div key={i} className='MenuItem'>
                {item.name}
                <span style={{color:this.props.rest.hex_color_primary}} className = 'MenuItemPrice'>
                  {item.price.substring(2)}
                </span>
              </div>
            )
          })}
        </div>
      )
    }
    return categories
  }

  render(){
    return (
      <div className="MenuContainer">
        <SectionHeader title='Menu' rest={this.props.rest}/>
        {this.props.rest.menu_header && <p style={{margin:'12px 24px', fontWeight: 900}}>{this.props.rest.menu_header}</p>}
        {this.renderMenuBody()}
      </div>
    );
  }
}

export default Menu;
