import React, { Component } from 'react'
import SectionHeader from '../SectionHeader/SectionHeader'
import Slider from "react-slick"
import LazyLoad from 'react-lazyload';
import ReactGA from 'react-ga'
import Modal from '../Modal/Modal'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import './Gallery.css'

class Gallery extends Component{

  constructor(props){
    super(props)
    this.state = {
      expanded: null
    }
  }

  componentDidMount(){
    ReactGA.initialize(this.props.rest.ga_id)
  }

  componentDidUpdate(prevProps){
    if (this.props.isVisible && !prevProps.isVisible){
      this.props.setRoute('gallery')
      ReactGA.pageview('Gallery')
    }
  }

  renderExpansion(){
    if (this.state.expanded) {
      return(
        <Modal close={()=>this.setState({expanded:null, expanded_text:null})}>
          <img style={{width: '100%', display:'block'}} src={this.state.expanded} alt={this.state.expanded_text}/>
          <h3 style={{textAlign:'center'}}>{this.state.expanded_text}</h3>
        </Modal>
      )
    }
  }

  renderGallery(){
    let cards = Object.keys(this.props.rest.gallery).map((img_key,i)=>{
      let pic = this.props.rest.gallery[img_key]
      return(
        <div key={i}>
          <LazyLoad>
            <img onClick={()=>this.setState({expanded:pic, expanded_text:img_key})} className='GalleryImg' src={pic} alt={img_key}/>
          </LazyLoad>
        </div>
      )
    })
    let settings = {
      dots: true,
      infinite: true,
      slidesToShow: window.innerWidth > 800 ? 2 : 1,
      slidesToScroll: 1,
      className:'GalleryCardContainer',
      autoplay: true,
      cssEase: "ease",
      speed: 800,
      autoplaySpeed: 1600,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      centerMode: true,
      centerPadding: window.innerWidth > 800 ? '120px' : '32px',
    }
    return(
      <Slider {...settings} ref={slider => (this.slider = slider)}>
        {cards}
      </Slider>
    )
  }

  render(){
    if (Object.keys(this.props.rest.gallery).length > 0){
      return (
        <div className="GalleryContainer">
          <SectionHeader title='Gallery' rest={this.props.rest}/>
          {this.renderGallery()}
          {this.renderExpansion()}
        </div>
      )
    }
    else return null
  }
}


function NextArrow(props) {
  const { onClick } = props;
  return (
    <div className='GalleryCardsNext' onClick={onClick}>
      <img src='./icons/next.png' style = {{width:'100%'}} alt='next'/>
    </div>
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div className='GalleryCardsPrev' onClick={onClick}>
      <img src='./icons/prev.png' style = {{width:'100%'}} alt='previous'/>
    </div>
  );
}

export default Gallery;
