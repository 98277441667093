import React, { Component } from 'react'
import Splash from './Splash/Splash'
import Location from './Location/Location'
import Menu from './Menu/Menu'
import Reviews from './Reviews/Reviews'
import Contact from './Contact/Contact'
import Gallery from './Gallery/Gallery'
import Header from './Header/Header'
import Loading from './Loading/Loading'
import Announcement from './Announcement/Announcement'
import CustomSection from './CustomSection/CustomSection'
import scrollToComponent from 'react-scroll-to-component'
import { withRouter } from 'react-router-dom'
import TrackVisibility from 'react-on-screen'
import ReactGA from 'react-ga'
import WebfontLoader from '@dr-kobros/react-webfont-loader';
import 'url-search-params-polyfill'
import './App.css'

class App extends Component{

  constructor(props){
    super(props);
    this.state = {
      rest: null,
      showAnnouncement: false,
      initialSplashRender: true
    }
    let utms = this.getUTMs()
    fetch('./react_config.json')
      .then(response => response.json())
      .then(data => {
        data['utms'] = utms
        this.setState(
        { rest:data },
        ()=>{
          ReactGA.initialize(this.state.rest.ga_id)
          this.setInitialRoute()
        })
      }
      )
  }

  componentDidMount(){
    window.setTimeout(()=>this.setState({showAnnouncement:true}),2000)
  }

  applyPrimaryFont(){
    if (this.state.rest.fonts && this.state.rest.fonts.length > 0){
      let primary_font = this.state.rest.fonts[0].split(':')[0]
      var headers = document.querySelectorAll('h1, h2, h3, h4, h5, h6, button');
      for (let header of headers) { 
        header.style.fontFamily = primary_font
      } 
    }
  }

  getUTMs(){
    let path = new URL(window.location)
    let utm_source = path.searchParams.get("utm_source")
    if (!utm_source) utm_source = ''
    let utm_medium = path.searchParams.get("utm_medium")
    if (!utm_medium) utm_medium = ''
    let utm_campaign = path.searchParams.get("utm_campaign")
    if (!utm_campaign) utm_campaign = ''
    let utms = '?'
    if (utm_source) utms += `utm_source=${utm_source}&`
    if (utm_medium) utms += `utm_medium=${utm_medium}&`
    if (utm_campaign) utms += `utm_campaign=${utm_campaign}`
    if (utms.slice(-1) === '&') utms = utms.substring(0,utms.length-1)
    if (utms.length < 2) return ''
    return utms
  }

  addUTMs(url){
    return url + this.state.rest.utms
  }

  setInitialRoute(){
    let path = this.props.location.pathname.substring(1)
    if (path.length === 0) return
      // check if path is a 301 redirect or else a standard url in sitegen
    if (this.state.rest.redirects){
      this.state.rest.redirects.forEach((redirect)=>{
        if (path === redirect.path){
          //path is setup as a redirect
          window.location.href = redirect.redirect_to;
        }
      })
    }
    // handle uppercase paths
    if (path[0] === path[0].toUpperCase()){
      window.location.href = path.toLowerCase()
    }

    // if not a predefined redirect, scroll to that section
    window.setTimeout(()=>{
      this.scrollToSection(path)
    },200)
  }

  scrollToSection(s){
    let offset = window.innerWidth > 800 ? -72 : -172
    scrollToComponent(this[s],{offset: offset, duration: 500, align: 'top'})
    this.setRoute(s)
  }

  closeAnnouncement(){
    this.setState({showAnnouncement:false})
  }

  setRoute(page){
    // DO NOT ALTER ROUTES ON CDN FOR DEVELOPMENT/TESTING
    this.setState({initialSplashRender:false})
    if (!window.location.origin.includes('cdn') && !this.state.initialSplashRender){
      this.props.history.replace(this.addUTMs(`/${page}`))
    }
  }

  renderAnnouncement(){
    if (this.state.rest.announcement && this.state.showAnnouncement){
      return(
        <Announcement rest={this.state.rest} close={()=>this.closeAnnouncement()} />
      )
    }
  }

  renderCustomSections(){
    if (this.state.rest.sections){
      return this.state.rest.sections.map((section,i)=>{
        return(
          <section key={i} ref={(sec)=>this[section.header.replace(/\s+/g, '-').toLowerCase()] = sec}>
            <TrackVisibility offset={200}>
              <CustomSection section={section} rest={this.state.rest} setRoute={(page)=>this.setRoute(page)}/>
            </TrackVisibility>
          </section>
        )
      })
    }
    return
  }

  renderDisclosures(){
    if (this.state.rest.disclosures){
      return <div className='Disclosures'>{this.state.rest.disclosures}</div>
    }
  }

  getFontConfig(){
    const font_config = {
      google: {
        families: ['Nunito Sans: 100,200,300,400,500,600,700,800,900'],
      }
    }
    if (this.state.rest.fonts){
      font_config['google']['families'].push(...this.state.rest.fonts)
    }
    return font_config
  }

  getBackground(){
    return window.innerWidth < 800 && this.state.rest.splash.background_mobile ? this.state.rest.splash.background_mobile : this.state.rest.splash.background
  }

  getYear(){
    let dt = new Date()
    return dt.getFullYear()
  }

  render(){
    if (this.state.rest){
      return (
        <WebfontLoader config={this.getFontConfig()} onStatus={()=>this.applyPrimaryFont()}>
          <div className="App">
            {this.renderAnnouncement()}
            <Header rest={this.state.rest} scrollToSection={(s)=>this.scrollToSection(s)}/>
            <img className="SplashBackground" 
              src={this.getBackground()} 
              alt='background' 
              style={{filter:`brightness(${this.state.rest.splash.background_brightness})`}}/>
            <section ref={(sec)=>this.splash = sec}>
              <TrackVisibility offset={200}>
                <Splash rest={this.state.rest} scrollToSection={()=>this.scrollToSection('location')} setRoute={(page)=>this.setRoute(page)} doNotUpdateRoute={this.state.initialSplashRender}/>
              </TrackVisibility>
            </section>
            <div className='PageSlider'>
              <section ref={(sec)=>this.location = sec}>
                <TrackVisibility offset={200}>
                  <Location rest={this.state.rest} setRoute={(page)=>this.setRoute(page)}/>
                </TrackVisibility>
              </section>
              <section ref={(sec)=>this.gallery = sec}>
                <TrackVisibility offset={200}>
                  <Gallery rest={this.state.rest} setRoute={(page)=>this.setRoute(page)}/>
                </TrackVisibility>
              </section>
              <section ref={(sec)=>this.reviews = sec}>
                <TrackVisibility offset={800}>
                  <Reviews rest={this.state.rest} setRoute={(page)=>this.setRoute(page)}/>
                </TrackVisibility>
              </section>
              {this.renderCustomSections()}
              <section ref={(sec)=>this.menu = sec}>
                <TrackVisibility offset={200}>
                  <Menu rest={this.state.rest} setRoute={(page)=>this.setRoute(page)}/>
                </TrackVisibility>
              </section>
              <section ref={(sec)=>this.contact = sec}>
                <TrackVisibility offset={200}>
                  <Contact rest={this.state.rest} setRoute={(page)=>this.setRoute(page)}/>
                </TrackVisibility>
              </section>
              {this.renderDisclosures()}
              <div id='siteVersion'>
                ©{this.getYear()} {this.state.rest.name}
              </div>
            </div>
          </div>
        </WebfontLoader>
      )
    }
    return <Loading/>
  }
}

export default withRouter(App);
