import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import App from './App';
import { BrowserRouter as Router, Route} from 'react-router-dom';

class Routes extends React.Component{

  componentDidMount(){
    let env = 'production'
    if (window.location.origin.includes('localhost')){
      env = 'development'
    }
    else if (window.location.origin.includes('cdn')){
      env = 'staging'
    }
    Sentry.init({
      dsn: "https://18927112639245f69d165445dfd679de@sentry.io/1553076",
      environment: env,
    });
  }

  render(){
    
    return(
      <>
        <Route path='/*' key={0} component={App}/>
      </>
    )
  }
}

ReactDOM.render(<Router><Routes/></Router>, document.getElementById('root'));