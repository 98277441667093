import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser';
import ReactGA from 'react-ga'
import './Announcement.css'

class Announcement extends Component{

  componentDidMount(){
    ReactGA.initialize(this.props.rest.ga_id)
    ReactGA.pageview('Announcement');
  }

  addDefaultSrc(e){
    e.target.src=this.props.rest.logo
  }

  render(){
    return (
      <div className='AnnouncementBackground' onClick={()=>this.props.close()}>
        <div className='AnnouncementContainer' onClick={(e)=>e.stopPropagation()}>
          <img onClick={()=>this.props.close()} className='AnnouncementClose' src='./icons/close.png' alt='close'/>
          <div className='AnnouncementBody'>{ReactHtmlParser(this.props.rest.announcement)}</div>
        </div>
      </div>
    );
  }
}

export default Announcement;