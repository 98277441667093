import React, { Component } from 'react';
import ScrollLock, { TouchScrollable } from 'react-scrolllock';
import './Modal.css';

class Modal extends Component {

  constructor(props) {
    super(props)
    this.state = {
      closing: false
    }
  }

  closeModal(e){
    if (e.target.className === 'Background' || e.target.className === 'Close'){
      console.log('yeet')
      this.setState({closing:true},()=>{
        window.setTimeout(()=>{
          this.props.close()
        },500)
      })
    }
  }

  render(){
    return (
      <ScrollLock>
        <div className='Background' id={this.state.closing ? 'BackgroundExit' : null} onClick={(e)=>this.closeModal(e,'background')}>
          <TouchScrollable>
            <div
            className="Modal"
            id={this.state.closing ? 'ModalExit' : null}
            style={{...this.props.style}}
            onClick={null}
            >
              <img src='./icons/close.png' className='Close' onClick={(e)=>this.closeModal(e,'x')} alt='close'/>
              {this.props.children}
            </div>
          </TouchScrollable>
        </div>
      </ScrollLock>
    )
  }
}

export default Modal;
