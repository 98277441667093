import React, { Component} from 'react';
import PlacesAutocomplete, {geocodeByAddress,getLatLng} from 'react-places-autocomplete';
 
class MapAutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state={
      address: this.props.address ? this.props.address.address : ''
    }
  }

  componentDidUpdate(prevProps){
    if (prevProps.address !== this.props.address){
      this.setState({address:this.props.address.address})
    }
  }
 
  handleChange = address => {
    this.setState({ address })
  }
 
  handleSelect = address => {
    this.setState({ address })
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => this.props.setAddress({address:address,lat:latLng.lat,lng:latLng.lng}))
      .catch(error => console.error('Error', error));
  };
 
  render() {
    const searchOptions = {
      types: ['address']
    }
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: 'Find your address...',
                className: 'restMapAddressInput',
              })}
            />
            <div className="restMapAutocompleteContainer">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer', padding: '4px' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer', padding: '4px' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default MapAutoComplete;