import React, { Component } from 'react'
import SectionHeader from '../SectionHeader/SectionHeader'
import ReactHtmlParser from 'react-html-parser';
import ReactGA from 'react-ga'
import './Contact.css'

class Contact extends Component{

  componentDidMount(){
    ReactGA.initialize(this.props.rest.ga_id)
  }

  componentDidUpdate(prevProps){
    if (this.props.isVisible && !prevProps.isVisible){
      this.props.setRoute('contact')
      ReactGA.pageview('Contact')
    }
  }

  handleSubmit(){
    ReactGA.event({
      category: this.props.rest.name,
      action: 'Contact Form Submit',
      label: ''
    })
  }

  render(){
    if (this.props.rest.contact){
      let contact_url = `//formspree.io/${this.props.rest.contact}`
      return (
        <div className="ContactContainer">
          <SectionHeader title='CONTACT US' rest={this.props.rest}/>
          <img src={this.props.rest.logo} className='ContactImg' alt='contact-img'/>
          <div className='ContactBody'>
            <div>{ReactHtmlParser('Reach out to learn more about us! Questions about our food, our location, or anything else? Contact us here:')}</div>
            <br/>
            <form action={contact_url} method="POST">
              <input type="hidden" name="_cc" value="formspree@hngr.co" />
              <input type="hidden" name="_cc" value="ryan@hngr.co" />
              <label className='ContactLabel' htmlFor="name">
                <input className='ContactInput' type="text" placeholder="Your name..." name="name"/>
              </label>
              <label className='ContactLabel' htmlFor="_replyto">
                <input className='ContactInput' type="email" placeholder="Your email..." name="_replyto"/>
              </label>
              <label className='ContactLabel' htmlFor="message">
                <textarea className='ContactInput' rows="3" placeholder="Your message..." name="message" />
              </label>
              <button className='ContactButton' style={{background:this.props.rest.hex_color_primary}} type="submit" onClick={()=>this.handleSubmit()}>SUBMIT</button>
            </form>
          </div>
        </div>
      )
    } else { return null }
  }
}

export default Contact;
