import React, { Component } from 'react'
import ReactGA from 'react-ga'
import './SectionHeader.css'

class SectionHeader extends Component{

  componentDidMount(){
    ReactGA.initialize(this.props.rest.ga_id)
  }

  handleClick(){
    ReactGA.event({
      category: this.props.rest.name,
      action: 'PDF Menu',
      label: ''
    })
  }

  renderMenuPDF(){
    if (this.props.title === "Menu" && this.props.rest.pdf_menu){
      return(
        <a onClick={()=>this.handleClick()} className='SectionHeaderMenuLink' href={this.props.rest.pdf_menu} target="_blank" rel="noopener noreferrer">
          <img src='./icons/pdf.png' className='SectionHeaderMenuImg' alt='menu'/>
          {window.innerWidth < 800 && <br/>}
          Click Here for PDF Menu
        </a>
      )
    }
  }

  render(){
    return (
      <div>
        <h1
          className="SectionHeaderContainer"
          style={{
            background: this.props.rest.hex_color_primary,
            boxShadow:`-8px -8px 0px 0px ${this.props.rest.hex_color_secondary}`
          }}
        >
          {this.props.title}
        </h1>
        {this.renderMenuPDF()}
      </div>
    );
  }
}

export default SectionHeader;
