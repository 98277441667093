import React, { Component } from 'react'
import ReactGA from 'react-ga'
import './Header.css'

class Header extends Component{

  componentDidMount(){
    ReactGA.initialize(this.props.rest.ga_id)
  }

  constructor(props){
    super(props)
    this.state = {
      mobile: window.innerWidth < 800,
      expanded: false
    }
  }

  handleLinkClick(link){
    this.setState({expanded:false})
    ReactGA.event({
      category: this.props.rest.name,
      action: link,
      label: 'Header Link'
    })
    this.props.scrollToSection(link)
  }

  handleSocialClick(button){
    ReactGA.event({
      category: this.props.rest.name,
      action: button,
      label: 'Header Link'
    })
    if (button === 'facebook') window.open(this.props.rest.facebook_link, '_blank');
    if (button === 'instagram') window.open(this.props.rest.instagram_link, '_blank');
    if (button === 'twitter') window.open(this.props.rest.twitter_link, '_blank');
  }

  handleOrderClick(){
    ReactGA.event({
      category: this.props.rest.name,
      action: 'Order Online',
      label: 'Header Link'
    })
  }

  renderHeaderLinks(){
    let linkStyle = this.state.mobile ? 'HeaderLinkMobile' : 'HeaderLink'
    let locationLink = this.props.rest.locations.length === 1 ? 'Address + Hours' : 'Locations'
    let links = [
      <div onClick={()=>this.handleLinkClick('location')} key={-1} className={linkStyle}>{locationLink}</div>
    ]
    if (Object.keys(this.props.rest.gallery).length > 0){
      links.push(<div onClick={()=>this.handleLinkClick('gallery')} key={-2} className={linkStyle}>Gallery</div>)
    }
    if (this.props.rest.reviews.length > 0){
      links.push(<div onClick={()=>this.handleLinkClick('reviews')} key={-3} className={linkStyle}>Reviews</div>)
    }
    if (this.props.rest.sections){
      this.props.rest.sections.forEach((section,i) => {
        links.push(
          <div onClick={()=>this.handleLinkClick(section.header.replace(/\s+/g, '-').toLowerCase())} key={i} className={linkStyle}>{section.header}</div>
        )
      })
    }
    links.push(<div onClick={()=>this.handleLinkClick('menu')} key={-4} className={linkStyle}>Menu</div>)
    if (this.props.rest.contact){
      links.push(<div onClick={()=>this.handleLinkClick('contact')} key={-5} className={linkStyle}>Contact Us</div>)
    }
    let order_link = this.props.rest.ordering_url ? this.props.rest.ordering_url + this.props.rest.utms : '/location'
    links.push(
      <a key={-6} href={order_link} rel={this.props.rest.ordering_url && "noopener noreferrer"} target={this.props.rest.ordering_url && "_blank"}>
        <button onClick={()=>this.handleOrderClick()} className='HeaderButton' style={{background:this.props.rest.hex_color_secondary}}>
          ORDER NOW
        </button>
      </a>
    )
    return links
  }

  toggleMenu(){
    this.setState({expanded:!this.state.expanded})
  }

  renderMobile(){
    if (this.state.expanded){
      return(
        <div className="HeaderContainer" style={{background:'rgba(0,0,0,.7)'}}>
          <img className='HeaderImg' src={this.props.rest.logo} onClick={()=>this.handleLinkClick('Splash')}  alt='logo'/>
          <img className='HeaderMenu' src='./icons/menu.png' alt='menu' onClick={()=>this.toggleMenu()}/>
          <div className='HeaderLinksMobile'>
            {this.renderHeaderLinks()}
          </div>
        </div>
      )
    }
    return(
      <div className="HeaderContainer">
        <img className='HeaderImg' src={this.props.rest.logo} onClick={()=>this.handleLinkClick('splash')}  alt='logo'/>
        {this.props.rest.facebook_link && <img className='HeaderSocialImg' style={{opacity:1}} src='./icons/facebook.png' onClick={()=>this.handleSocialClick('facebook')} alt='facebook'/>}
        {this.props.rest.instagram_link && <img className='HeaderSocialImg' style={{opacity:1}} src='./icons/instagram.png' onClick={()=>this.handleSocialClick('instagram')} alt='facebook'/>}
        {this.props.rest.twitter_link && <img className='HeaderSocialImg' style={{opacity:1}} src='./icons/twitter.png' onClick={()=>this.handleSocialClick('twitter')} alt='twitter'/>}
        <img className='HeaderMenu' src='./icons/menu.png' alt='menu' onClick={()=>this.toggleMenu()}/>
      </div>
    )
  }

  renderDesktop(){
    return (
      <div className="HeaderContainer">
        <img className='HeaderImg' src={this.props.rest.logo} onClick={()=>this.handleLinkClick('splash')} alt='logo'/>
        {this.props.rest.facebook_link && <img className='HeaderSocialImg' src='./icons/facebook.png' onClick={()=>this.handleSocialClick('facebook')} alt='facebook'/>}
        {this.props.rest.instagram_link && <img className='HeaderSocialImg' src='./icons/instagram.png' onClick={()=>this.handleSocialClick('instagram')} alt='facebook'/>}
        {this.props.rest.twitter_link && <img className='HeaderSocialImg' src='./icons/twitter.png' onClick={()=>this.handleSocialClick('twitter')} alt='twitter'/>}
        <div className='HeaderLinks'>
          {this.renderHeaderLinks()}
        </div>
      </div>
    )
  }

  render(){
    if (this.state.mobile){
      return this.renderMobile()
    } else {
      return this.renderDesktop()
    }
  }
}

export default Header;
