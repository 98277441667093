import React, { Component } from 'react'
import SectionHeader from '../SectionHeader/SectionHeader'
import MapMarker from './MapMarker'
import MapAutoComplete from './MapAutoComplete'
import GoogleMapReact from 'google-map-react'
import Slider from "react-slick"
import ReactGA from 'react-ga'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import './Location.css'

class Location extends Component{

  constructor(props){
    super(props)
    this.state = {
      address: null,
      addressCenter: false,
      selectedLocation: 0
    }
  }

  componentDidMount(){
    ReactGA.initialize(this.props.rest.ga_id)
  }

  componentDidUpdate(prevProps){
    if (this.props.isVisible && !prevProps.isVisible){
      this.props.setRoute('location')
      ReactGA.pageview('Location');
    }
  }

  getLocationFromKey(key){
    return this.props.rest.locations[key]
  }

  setAddress(addresObj){
    this.setState({address:addresObj,addressCenter:true})
    ReactGA.event({
      category: this.props.rest.name,
      action: 'User Address Entry',
      label: ''
    })
  }

  selectLocation(key){
    this.setState({selectedLocation: key, addressCenter:false})
    this.slider.slickGoTo(key)
    ReactGA.event({
      category: this.props.rest.name,
      action: 'Location Card Change',
      label: 'Card: ' + key
    })
  }

  renderRestMarkers(){
    return this.props.rest.locations.map((location, i)=>{
      return(
        <MapMarker selectLocation={(key)=>this.selectLocation(key)} key={i} selectKey={i} text={location.name} rest={this.props.rest} lat={location.lat} lng={location.lng}/>
      )
    })
  }

  renderAddressMarker(){
    if (this.state.address){
      return(
        <MapMarker type='address' lat={this.state.address.lat} lng={this.state.address.lng}/>
      )
    }
  }

  renderMap(){
    return(
      <div className='LocationMap'>

        <MapAutoComplete setAddress={(addressObj)=>this.setAddress(addressObj)} address={this.state.address}/>

        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyDx3LPzjrY1Y4258OJOHvWCzVqoR0TUi4o'}}
          defaultCenter={{lat: 41.88,lng: -87.63}}
          defaultZoom={14}
          center={
            this.state.address && this.state.addressCenter ?
            {
              lat: this.state.address.lat,
              lng: this.state.address.lng
            } :
            {
              lat: parseFloat(this.getLocationFromKey(this.state.selectedLocation)['lat'] -.001),
              lng: parseFloat(this.getLocationFromKey(this.state.selectedLocation)['lng'] +.006),
            }
          }
        >

          {this.renderRestMarkers()}
          {this.renderAddressMarker()}

        </GoogleMapReact>
        </div>
    )
  }

  handleClick(address){
    ReactGA.event({
      category: this.props.rest.name,
      action: 'Order Online',
      label: 'Location: ' + address
    })
  }

  formatPhone(num){
    let phone = num
    phone = phone.replace(/-/g, "")
    phone = '(' + phone.slice(0,3)+") "+phone.slice(3,6)+"-"+phone.slice(6,15)
    return phone
  }

  renderLocationCards(){
    if (this.props.rest.locations.length === 1){
      let location = this.props.rest.locations[0]
      let href_phone = `tel:${location.phone}`
      return(
        <div className='LocationCardWrapper' style={window.innerWidth > 800 ? {display:'inline-block', width: '40%', verticalAlign:'middle'} : null}>
          <div className='LocationCard'>
            <img className='LocationCardLogo' src={this.props.rest.logo} alt='logo'/>
            <h6 className='LocationCardHeader'>{location.name}</h6>
            <div className='LocationCardAddress' onClick={e=>e.stopPropagation()}>{location.address}<br/>{location.city}, {location.state} {location.zip}</div>
            <a href={href_phone}><div>{this.formatPhone(location.phone)}</div></a>  
            <a href={location.order_link + this.props.rest.utms} target="_blank" rel="noopener noreferrer">
              <button onClick={()=>this.handleClick(location.address)} className='LocationCardButton' style={{background:this.props.rest.hex_color_secondary}}>ORDER ONLINE</button>
            </a>  
            <div className='LocationCardHours'><span style={{fontWeight:900}}>HOURS</span><br/>
              Mon: {location.hours[1]}<br/>
              Tues: {location.hours[2]}<br/>
              Wed: {location.hours[3]}<br/>
              Thur: {location.hours[4]}<br/>
              Fri: {location.hours[5]}<br/>
              Sat: {location.hours[6]}<br/>
              Sun: {location.hours[0]}<br/>
            </div>

          </div>
        </div>
      )
    }
    let cards = this.props.rest.locations.map((location,i)=>{
      let href_phone = `tel:${location.phone}`
      return(
        <div key={i} className='LocationCardWrapper'>
          <div className='LocationCard'>
            <img className='LocationCardLogo' src={this.props.rest.logo} alt='logo'/>
            <h6 className='LocationCardHeader'>{location.name}</h6>
            <div className='LocationCardAddress'>{location.address}<br/>{location.city}, {location.state} {location.zip}</div>
            <a href={href_phone}><div>{this.formatPhone(location.phone)}</div></a>  
            <a href={location.order_link + this.props.rest.utms} target="_blank" rel="noopener noreferrer">
              <button onClick={()=>this.handleClick(location.address)} className='LocationCardButton' style={{background:this.props.rest.hex_color_secondary}}>ORDER ONLINE</button>
            </a>  
            <div className='LocationCardHours'><span style={{fontWeight:900}}>HOURS</span><br/>
              Mon: {location.hours[1]}<br/>
              Tues: {location.hours[2]}<br/>
              Wed: {location.hours[3]}<br/>
              Thur: {location.hours[4]}<br/>
              Fri: {location.hours[5]}<br/>
              Sat: {location.hours[6]}<br/>
              Sun: {location.hours[0]}<br/>
            </div>

          </div>
        </div>
      )
    })
    let settings = {
      dots: true,
      infinite: false,
      slidesToShow: 1.05,
      className:'LocationCardsContainer',
      nextArrow: this.state.selectedLocation === this.props.rest.locations.length -1 ? null : <NextArrow />,
      prevArrow: this.state.selectedLocation === 0 ? null : <PrevArrow />,
      centerPadding: '20px',
      centerMode:true,
      beforeChange: (current, next) => {this.selectLocation(next)}
    }
    return(
      <Slider {...settings} ref={slider => (this.slider = slider)}>
        {cards}
      </Slider>
    )
  }

  renderButtons(){
    if (this.props.rest.locations.length > 3){
      return(
        <div className='LocationButtons'>
          {this.props.rest.locations.map((location, i) => (
            <button
              style={{background: this.state.selectedLocation === i ? this.props.rest.hex_color_primary : this.props.rest.hex_color_secondary}}
              onClick={()=>this.setState({selectedLocation: i})}
              key={i}
              className='LocationListButton'>
              {location.name}
            </button>
          ))}
        </div>
      )
    }
  }

  renderSelectedLocation(){
    let location = this.props.rest.locations[this.state.selectedLocation]
    let href_phone = `tel:${location.phone}`
    let fullAddress=location.name + ', ' + location.address + ', ' + location.city + ', ' + location.state + ' ' + location.zip
    let addressURL=encodeURIComponent(fullAddress.trim())
    return(
      <div className='LocationSelected'>
        <div className='LocationSelectedText'>
          <h6 className='LocationSelectedHeader'>{location.name}</h6>
          <a href={location.order_link + this.props.rest.utms} target="_blank" rel="noopener noreferrer">
            <button onClick={()=>this.handleClick(location.address)} className='LocationCardButton' style={{background:this.props.rest.hex_color_secondary}}>ORDER NOW</button>
          </a> 
          <a href={href_phone}><div className='LocationPhone'>{this.formatPhone(location.phone)}</div></a>  
          <div className='LocationCardAddress'>{location.address}<br/>{location.city}, {location.state} {location.zip}</div>
          <a href={`https://maps.google.com/maps/dir/?api=1&destination=${addressURL}`} target="_blank" rel="noopener noreferrer">
            <div className='LocationDirections'>Get directions</div>
          </a>
        </div>
        <div className='LocationSelectedText'>
          <div className='LocationListHours'><span style={{fontWeight:900}}>HOURS</span><br/>
            Mon: {location.hours[1]}<br/>
            Tues: {location.hours[2]}<br/>
            Wed: {location.hours[3]}<br/>
            Thur: {location.hours[4]}<br/>
            Fri: {location.hours[5]}<br/>
            Sat: {location.hours[6]}<br/>
            Sun: {location.hours[0]}<br/>
          </div>
        </div>
      </div>
    )
  }

  renderLocationList(){
    return this.props.rest.locations.map((location,i)=>{
      let href_phone = `tel:${location.phone}`
      let fullAddress=location.name + ', ' + location.address + ', ' + location.city + ', ' + location.state + ' ' + location.zip
      let addressURL=encodeURIComponent(fullAddress.trim())
      return(
        <div className='LocationListElement'>
          <div className='LocationSelectedText'>
            <h6 className='LocationSelectedHeader'>{location.name}</h6>
            <a href={location.order_link + this.props.rest.utms} target="_blank" rel="noopener noreferrer">
              <button onClick={()=>this.handleClick(location.address)} className='LocationCardButton' style={{background:this.props.rest.hex_color_secondary}}>ORDER NOW</button>
            </a> 
            <a href={href_phone}><div className='LocationPhone'>{this.formatPhone(location.phone)}</div></a> 
            <div className='LocationCardAddress'>{location.address}<br/>{location.city}, {location.state} {location.zip}</div>
            <a href={`https://maps.google.com/maps/dir/?api=1&destination=${addressURL}`} target="_blank" rel="noopener noreferrer">
              <div className='LocationDirections'>Get directions</div>
            </a> 
          </div>
          <div className='LocationSelectedText'>
            <div className='LocationListHours'><span style={{fontWeight:900}}>HOURS</span><br/>
              Mon: {location.hours[1]}<br/>
              Tues: {location.hours[2]}<br/>
              Wed: {location.hours[3]}<br/>
              Thur: {location.hours[4]}<br/>
              Fri: {location.hours[5]}<br/>
              Sat: {location.hours[6]}<br/>
              Sun: {location.hours[0]}<br/>
            </div>
          </div>
        </div>
      )
    })
  }

  renderContent(){
    if (this.props.rest.location_type && this.props.rest.location_type === 'LIST' && this.props.rest.locations.length > 3){
      return(
        <div className='LocationList' style={{justifyContent:'space-around', alignItems:'center'}}>
          {this.renderButtons()}
          {this.renderSelectedLocation()}
        </div>
      )
    }
    if (this.props.rest.location_type && this.props.rest.location_type === 'LIST') {
      return(
        <div className='LocationList'>
          {this.renderLocationList()}
        </div>
      )
    }
    else {
      return(
        <>
          {this.renderMap()}
          {this.renderLocationCards()}
        </>
      )
    }
  }

  render(){
    let title = this.props.rest.locations.length === 1 ? 'Address & Hours' : 'Locations'
    return (
      <div className="LocationContainer">
        <SectionHeader title={title} rest={this.props.rest}/>
        {this.renderContent()}
      </div>
    );
  }
}


function NextArrow(props) {
  const { onClick } = props;
  return (
    <div className='LocationCardsNext' onClick={onClick}>
      <img src='./icons/next.png' style = {{width:'100%'}} alt='next'/>
    </div>
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div className='LocationCardsPrev' onClick={onClick}>
      <img src='./icons/prev.png' style = {{width:'100%'}} alt='previous'/>
    </div>
  );
}

export default Location;
