import React, { Component } from 'react'
import SectionHeader from '../SectionHeader/SectionHeader'
import Slider from "react-slick"
import ReactGA from 'react-ga'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import './Reviews.css'

class Reviews extends Component{

  componentDidMount(){
    ReactGA.initialize(this.props.rest.ga_id)
  }

  componentDidUpdate(prevProps){
    if (this.props.isVisible && !prevProps.isVisible){
      this.props.setRoute('reviews')
      ReactGA.pageview('Reviews')
    }
  }

  handleClick(review){
    ReactGA.event({
      category: this.props.rest.name,
      action: review.type + 'Review Link',
      label: review.name + "'s Review"
    })
  }

  renderReviews(){
    let cards = this.props.rest.reviews.map((review,i)=>{
      let img_url = review.type === 'google' ? './icons/google.jpg' : './icons/yelp.jpeg'
      let button_style = review.type === 'google' ? {background: '#4284f4'} : {background: '#a51014'}
      return(
        <div key={i} className='ReviewWrapper'>
          <div className='ReviewCard'>
            <img className='ReviewCardLogo' src={img_url} alt='logo'/>
            <div className='ReviewCardHeader'>{review.name}</div>
            <div className='ReviewCardBody'>"{review.body}"</div>
            <a href={review.url} target="_blank" rel="noopener noreferrer">
              <button className='ReviewCardButton' style={button_style}>VIEW ON {review.type}</button>
            </a>  

          </div>
        </div>
      )
    })
    let settings = {
      dots: true,
      infinite: true,
      slidesToShow: window.innerWidth < 800 ? 1 : 2.4,
      className:'ReviewsCardContainer',
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      centerMode: true,
      centerPadding: window.innerWidth < 800 ? '12px' : '42px'
    }
    return(
      <Slider {...settings} ref={slider => (this.slider = slider)}>
        {cards}
      </Slider>
    )
  }

  render(){
    if (this.props.rest.reviews && this.props.rest.reviews.length > 0){
      return (
        <div className="ReviewsContainer">
          <SectionHeader title='Reviews' rest={this.props.rest}/>
          {this.renderReviews()}
        </div>
      )
    }
    else return null
  }
}


function NextArrow(props) {
  const { onClick } = props;
  return (
    <div className='ReviewsCardsNext' onClick={onClick}>
      <img src='./icons/next.png' style = {{width:'100%'}} alt='next'/>
    </div>
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div className='ReviewsCardsPrev' onClick={onClick}>
      <img src='./icons/prev.png' style = {{width:'100%'}} alt='previous'/>
    </div>
  );
}

export default Reviews;
