import React, { Component } from 'react'
import SectionHeader from '../SectionHeader/SectionHeader'
import ReactHtmlParser from 'react-html-parser';
import ReactGA from 'react-ga'
import './CustomSection.css'

class CustomSection extends Component{

  componentDidMount(){
    ReactGA.initialize(this.props.rest.ga_id)
    ReactGA.pageview(this.props.section.header);
  }

  componentDidUpdate(prevProps){
    if (this.props.isVisible && !prevProps.isVisible){
      let url = this.props.section.header.replace(/\s+/g, '-').toLowerCase()
      this.props.setRoute(url)
      ReactGA.pageview(url);
    }
  }

  addDefaultSrc(e){
    e.target.src = this.props.rest.logo
  }

  render(){
    return (
      <div className="CustomSectionContainer">
        <SectionHeader title={this.props.section.header} rest={this.props.rest}/>
        <img onError={(e)=>this.addDefaultSrc(e)} src={this.props.section.img} className='CustomSectionImg' alt='custom-img'/>
        <div className='CustomSectionBody'>{ReactHtmlParser(this.props.section.body)}</div>
      </div>
    );
  }
}

export default CustomSection;
